
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { ISequence } from "@/schemas/ISequence";

import clientCache from "@/utils/cacheUtils";

@Component({
  name: "SettingSelection",
})
export default class SettingSelection extends Vue {
  @Prop({ required: true, type: String })
  readonly code!: string ;
  @Prop({ required: true, type: Function })
  readonly openSelectedSettingsHandler!: CallableFunction;
  @Prop({ required: true, type: Boolean })
  readonly activitySelection!: boolean;

  private sequence: ISequence = { title: "", description: "", is_open: false };
  enabledSettings: string[] = [];
  allEnabled = false;

  created(): void {
    if (this.code) {
      this.$store.dispatch("sequence/fetchFullByCode", {
        code: this.code,
        next: (sequence: ISequence) => {
          this.sequence = sequence;
          this.allEnabled =
            sequence.settings?.every((setting) => setting.is_open) || false;
        },
      });
    }
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  openActivity(id: string): void {
    this.enabledSettings.push(id);
  }

  disableActivity(id: string): void {
    this.enabledSettings = this.enabledSettings.filter(
      (setting_id) => setting_id !== id
    );
  }

  onCheckChange(isChecked: boolean, setting_id: string): void {
    isChecked
      ? this.openActivity(setting_id)
      : this.disableActivity(setting_id);
  }

  openSelectedSettings(): void {
    clientCache.cache(
      `currentOpenedSettings:${this.sequence.id}`,
      JSON.stringify(this.enabledSettings)
    );
    this.openSelectedSettingsHandler(this.enabledSettings);
  }
}
