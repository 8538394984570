
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import { ISequence } from "@/schemas/ISequence";
import { ISession } from "@/schemas/ISession";
import { IUserSessions } from "@/schemas/IUserSessions";
import { SessionState } from "@/schemas/Enums";
import clientCache from "@/utils/cacheUtils";
import { UFliterals } from "@/utils/literals";
import VueRouter, { NavigationFailure } from "vue-router";
import { IActivity } from "@/schemas/IActivity";
import { mapActions } from "vuex";

@Component({
  name: "" + "GroupsPage",
  components: {
    draggable,
  },

  methods: {
    ...mapActions("setting", ["fetchSettingScores", "fetchSettingSessions", "fetchSettingFeedbacks"])
  },
  mounted: function () {
    if (this.code) {
      this.$store.dispatch("sequence/fetchFullByCode", {
        code: this.code,
        next: (sequence: ISequence) => {
          this.sequence=sequence;
          if(this.sequence.is_open){
            this.generateLatestGroups();
          }
        },
      });
    }
    // this.getUsersPerGroups();

  },
})
export default class GroupsPage extends Vue {
  @Prop({ type: String, required: true })
  readonly code: string | undefined;
  @Prop({ type: Function, required: true })
  removeUser!: CallableFunction | undefined;
  @Prop({ type: Function, required: true })
  settingsToOpenUnsetHandler!: CallableFunction;
  @Prop({ type: Array, required: true })
  approved!: {
    code: string;
    email: string;
    otp: string;
    username: string;
  }[];


  // Current sequence
  private sequence: ISequence | undefined = undefined;
  // List of users approved for the current sequence
  // private approved: {
  //   username: string;
  //   code: string;
  //   id: string;
  // }[] = [];
  // List of user groups
  groups: { username: string; code: string; id: string }[][] = [];
  // Number of user required per groups
  usersPerGroups = 4;
  private lastLength = 0;
  settingsToOpen: string[] = [];
  settingsToTransmit: string[] = [];

  data(): any {
    return {
      sequence : {},
      lastGroup : [],
      firstIteration : true
    }
  }

  @Watch("approved")
  approvedUpdate(
    value: {
      code: string;
      email: string;
      otp: string;
      username: string;
    }[]
  ): void {
    if(this.firstIteration == false || (this.sequence.is_open == false && this.firstIteration == true )){
      let alreadyDispatched = this.groups.flat();

      let newLength = value.length;
      // A user has been removed
      if (this.lastLength > newLength) {

        for (const groupsKey in this.groups) {
          Vue.set(
            this.groups,
            groupsKey,
            this.groups[groupsKey].filter((user) => this.approved.includes(user))
          );
        }
        this.groups = this.groups.filter((group) => group.length);
      }
      // A user has been added to the approved ones
      if (this.lastLength < newLength) {

        let added = this.approved.filter(
          (user) => !alreadyDispatched.map((u) => u.username).includes(user.username)
        );

        added = added.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.username === value.username
            ))
        )
        if (added.length==1) {
          for (const index in this.groups) {
            while (
              this.groups[index].length < this.usersPerGroups &&
              added.length
              ) {
              let toAdd = added.shift();
              Vue.set(this.groups, index, [...this.groups[index], toAdd]);
            }
          }
          // tous les groupes existants sont remplis, il faut maintenant trouver la repartition optimale
          if (added.length) {
            let slice = Math.ceil(added.length / this.usersPerGroups);

            for (let i = 0; i < slice; i++) {
              this.groups.push([...added.splice(0, this.usersPerGroups)]);
            }
          }
        }
        if (added.length) {
          for (const index in this.groups) {
            while (
              this.groups[index].length < this.usersPerGroups &&
              added.length
              ) {
              let toAdd = added.shift();
              Vue.set(this.groups, index, [...this.groups[index], toAdd]);
            }
          }
          if (added.length) {
            let newgroup= Math.floor(added.length / this.usersPerGroups)
            if (newgroup==0){
              newgroup=1
            }
            for (let i = 0; i < newgroup; i++) {
              this.groups.push([...added.splice(0, this.usersPerGroups)]);
            }
          }
          if (added.length) {
            if(this.usersPerGroups==4){ //On fait des groupes de 4 ou un groupe de 5
              if(this.groups.length >= added.length && added.length<3){
                let index=0
                while (added.length && index < this.groups[index].length) {
                  let toAdd = added.shift();
                  if(this.groups[index].length < 5) {
                    Vue.set(this.groups, index, [...this.groups[index], toAdd]);
                  }
                  index++
                }
                if(added.length){
                  this.groups.push([...added.splice(0, this.usersPerGroups)]); //a verif, cas peu probable
                }
              }
              else{
                if(added.length==3){
                  this.groups.push([added[0], added[1], added[2]]);
                }
                else{ // n'arrive que si 6 élèves
                  this.groups.push([added[0], added[1]]);
                  let transfert = this.groups[0].pop()
                  this.groups[1].push(transfert)
                }
              }
            }

          }
        }
      }
      this.lastLength = value.length;
    }
  }



  created(): void {
    this.lastLength = this.approved.length;
  }

  removeGroup(index: number): void {
    this.groups.splice(index, 1);
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUsersPerGroups(): void {
    this.usersPerGroups=4
  }

  /**
   * Generated random groups from the list of approved users
   */
  generateLatestGroups():void {
    let latest= this.sequence.settings[0]
    for(let i = 1; i< this.sequence.settings.length ; i++){
      if(latest.opened_at==null && this.sequence.settings[i].opened_at!=null){
        latest=this.sequence.settings[i]
      }
      else if(latest.opened_at!=null && this.sequence.settings[i].opened_at!=null && latest.opened_at < this.sequence.settings[i].opened_at){
        latest=this.sequence.settings[i]
      }
    }
    let settingId=latest.id
    this.fetchSettingSessions({
      settingId,
      next: (
        groups: {
          users: string[];
          status: string;
          session_id: string;
          started_time: string;
        }[]
      ) => {
        this.lastGroup = groups;
        this.groups = [];

        this.$store.dispatch("sequence/getApprovedUsers", {
          sequenceCode: this.code,
          next: (approved: string[]) => {
            let approvedUsers = [...approved].map((request: string) =>
              JSON.parse(request)
            );
            let users = approvedUsers.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.username === value.username
                ))
            )
            for (let i = 0; i < this.lastGroup.length; i++) {
              this.groups.push([])
              for (let j = 0; j < this.lastGroup[i].users.length; j++) {
                let currentUser = {}
                currentUser = users.find((element) => element.username == this.lastGroup[i].users[j])
                if (currentUser) {
                  this.groups[i].push(currentUser)
                  let position = users.indexOf(currentUser)
                  users.splice(position, 1)
                }
              }

            }
            this.firstIteration=false
          }

        })
      }
    })

  }

  generateRandomGroups(): void {
    this.lastLength = this.approved.length;
    let approvedUsers = [...this.approved];
    let users = approvedUsers.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.username === value.username
        ))
    )
    let nbGroups = Math.floor(users.length / this.usersPerGroups);
    if (nbGroups == 0){
      nbGroups=1
    }
    this.groups = [];
    this.getUsersPerGroups();
    for (let i = 0; i < nbGroups; i++) {
      let group = [];
      for (let j = 0; j < this.usersPerGroups; j++) {
        if (users.length) {
          let rand = Math.floor(Math.random() * users.length);
          group.push(users[rand]);
          users.splice(rand, 1);
        }
      }
      if (group.length) this.groups.push(group);
    }
    if(users.length){
      if(users.length==3){
        this.groups.push([users[0], users[1], users[2]]);
      }
      else if(this.groups.length >= users.length){

        let index = 0
        while (users.length) {
          let toAdd = users.shift();
          if(this.groups[index].length < 5) {
            Vue.set(this.groups, index, [...this.groups[index], toAdd]);
          }
          index++
        }
      }
      else{

        this.groups.push([users[0], users[1]]);
        let transfert = this.groups[0].pop()

        this.groups[1].push(transfert)
      }
    }
  }

  confirmGroups(): void {
    // Fetch a fresh instance of the current sequence
    this.$store.dispatch("sequence/fetchFullByCode", {
      code: this.code,
      next: (sequence: ISequence) => {
        this.sequence = sequence;
        // First, check that we know which settings to open
        this.settingsToOpen = JSON.parse(
          clientCache.get(`currentOpenedSettings:${this.sequence.id}`)
        );
        if (!this.settingsToOpen || !this.settingsToOpen?.length) {
          this.settingsToOpenUnsetHandler();
          return;
        }
        // Second, we check that there are at least one group
        // Then, we check that user have ids
        if (!this.sequence.is_open) {
          console.log("-------------------------");
          console.log("sequence NOT opened");
          console.log("-------------------------");
          this.$store.dispatch("sequence/approveRequests", {
            sequenceCode: this.code,
            next: () => {
              this.$store.dispatch("sequence/getApprovedUsers", {
                sequenceCode: this.code,
                next: (approved: string[]) => {
                  let approvedUsers = [...approved].map((request: string) =>
                    JSON.parse(request)
                  );
                  let groupsToAssign = [...this.groups].filter(
                    (group) => !!group.length
                  );
                  let settings = this.sequence.settings?.filter(
                    (sett) => !!this.settingsToOpen.find((s) => s === sett.id)
                  );
                  if (settings?.length && groupsToAssign.length) {
                    for (const setting of settings) {
                      for (const group of groupsToAssign) {
                        this.$store.dispatch("session/createSession", {
                          session: {
                            status: SessionState.Created,
                            setting_id: setting.id,
                            activity_id: setting.activity_id,
                          },
                          next: (createdSession: ISession) => {
                            let userSessions: IUserSessions[] = [];
                            let user_ids = group.map(
                              (user) =>
                                user.id ||
                                approvedUsers.find(
                                  (u) => u.username === user.username
                                ).id
                            );
                            console.log("-------------------------");
                            console.log("attribution des user a la session");
                            console.log(group);
                            console.log(user_ids);
                            console.log("-------------------------");
                            user_ids.forEach((user_id) => {
                              userSessions.push({
                                user_id,
                                sequence_id: sequence.id,
                              });
                            });
                            this.$store
                              .dispatch("session/addUserToSession", {
                                sessionId: createdSession.id,
                                userSessions,
                              })
                              .then(() => {
                                // this.settingsToTransmit.push(setting.id)
                                // console.log("push!")
                                // console.log(this.settingsToTransmit)
                                let now = new Date(Date.now());
                                now.setHours(now.getHours() + 2);
                                this.$store.dispatch("setting/updateSetting", {
                                  setting: { opened_at: now },
                                  id: setting.id,
                                });
                                clientCache.clear(
                                  `currentOpenedSettings:${this.sequence.id}`
                                );
                              });
                          },
                        });
                      }
                    }
                  }

                  if (this.sequence.id) {
                    const { isNavigationFailure, NavigationFailureType } =
                      VueRouter;
                    this.$router.push({
                      name: "sequence",
                      params: {
                        id: this.sequence.id,
                        toRefresh : true,
                      },
                    })
                      .catch((failure: NavigationFailure) => {
                        if (
                          !isNavigationFailure(
                            failure,
                            NavigationFailureType.deplicated
                          )
                        ) {
                          throw new Error(
                            `Router error ${failure.type} while navigating from ${failure.from} to ${failure.to}.`
                          );
                        }
                      });
                  } else {
                    this.$router.push({
                      name: "home",
                    });
                  }
                },
              });
            },
          });
        } else {
          this.$store.dispatch("sequence/approveRequests", {
            sequenceCode: this.code,
            next: (approvedUser) => {
              console.log("-------------------------");
              console.log("sequence OPEN");
              console.log("-------------------------");
              let groupsToAssign = [...this.groups].filter(
                (group) => !!group.length
              );
              let currentOpenedSettings: string[] = JSON.parse(
                clientCache.get(`currentOpenedSettings:${this.sequence.id}`)
              );
              let settings = this.sequence.settings?.filter(
                (sett) => !!currentOpenedSettings.find((s) => s === sett.id)
              );

              if (settings?.length && groupsToAssign.length) {
                for (const setting of settings) {
                  for (const group of groupsToAssign) {
                    this.$store.dispatch("session/createSession", {
                      session: {
                        status: SessionState.Created,
                        setting_id: setting.id,
                        activity_id: setting.activity_id,
                      },
                      next: (createdSession: ISession) => {
                        let userSessions: IUserSessions[] = [];
                        let user_ids = group.map(
                          (user) =>
                            user.id ||
                            approvedUser.find(
                              (u) => u.username === user.username
                            ).id
                        );
                        console.log("-------------------------");
                        console.log("attribution des user a la session");
                        console.log(group);
                        console.log(user_ids);
                        console.log("-------------------------");

                        user_ids.forEach((user_id) => {
                          userSessions.push({
                            user_id,
                            sequence_id: sequence.id,
                          });
                        });
                        console.log("-------------------------");
                        console.log("userSessions");
                        console.log(userSessions);
                        console.log("-------------------------");
                        this.$store
                          .dispatch("session/addUserToSession", {
                            sessionId: createdSession.id,
                            userSessions,
                          })
                          .then(() => {
                            let now = new Date(Date.now());
                            now.setHours(now.getHours() + 2);
                            this.$store.dispatch("setting/updateSetting", {
                                setting: { opened_at: now },
                                id: setting.id,
                              },
                            );
                          })
                          .then(() => {console.log("update done")});
                      },
                    });
                  }
                }
              }

              if (this.sequence.id) {
                this.$router.push({
                  name: "sequence",
                  params: {
                    id: this.sequence.id,
                    toRefresh : true,
                  },
                });
              } else {
                this.$router.push({
                  name: "home",
                });
              }
            },
          });
        }
      },
    });
  }

  addGroup(): void {
    this.groups.push([]);
  }

  removeUserHandler(user: {
    username: string;
    code: string;
    email: string;
  }): void {
    let groupIndex = this.groups.findIndex((group) => group.includes(user));
    let updated = this.groups[groupIndex];
    updated.splice(
      updated.findIndex((u) => u.email === user.email),
      1
    );
    Vue.set(this.groups, groupIndex, updated);
    this.lastLength -= 1;
    this.removeUser(user);
  }
}
