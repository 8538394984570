import { render, staticRenderFns } from "./SettingSelection.vue?vue&type=template&id=288e8819&scoped=true&"
import script from "./SettingSelection.vue?vue&type=script&lang=ts&"
export * from "./SettingSelection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288e8819",
  null
  
)

export default component.exports